import { Component, input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, RouterLink } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PATHS } from '../../../app.constants';
import { AuthService } from '../../services/auth.service';
import { FlagsService } from '../../services/flags.service';
import { AddFlagModalComponent, AddFlagModalSize } from '../../../flags/components/add-flag-modal/add-flag-modal.component';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { SubscribableBaseComponent } from '../subscribable-base/subscribable-base.component';
import Country from '../../../shared/models/country.model';
import { Location } from '@angular/common';
import { HeaderService } from '../../services/header.service';
import { IHeaderFormat } from '../../services/header.interfaces';
import type { IAddFlagData } from '../../../flags/components/add-flag-modal/add-flag-modal.interfaces';
import { ExtendedMenuComponent } from '../extended-menu/extended-menu.component';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

const DEFAULT_AFTER_LOGIN = `/${PATHS.wall}`;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dflgr-header-app',
    standalone: true,
    imports: [MatToolbarModule, MatButtonModule, MatIconModule, RouterLink, CountryFlagComponent, ExtendedMenuComponent],
    templateUrl: './header-app.component.html',
    styleUrls: ['./header-app.component.scss']
})
export class HeaderAppComponent extends SubscribableBaseComponent implements OnInit {
	readonly #modalHandler = inject(MatDialog);
	readonly #router = inject(Router);
	readonly #location = inject(Location);
	readonly #cdRef = inject(ChangeDetectorRef);
	readonly #flagsProvider = inject(FlagsService);
	readonly #headerProvider = inject(HeaderService);
	readonly #authProvider = inject(AuthService);

	@HostBinding('attr.role') readonly role = 'toolbar';
	readonly title = input<string>();
	header: IHeaderFormat;
	showLogged = false;
	private redirectingTo: string;

	/**
	 * Checks if not currently on the Registration page
	 */
	get notInReg (): boolean {
		return this.#router.url !== `/${PATHS.register}`;
	}

	private onLoggedChanged(logged: boolean) {
		if (logged) {
			if (this.updateLoggedStatus()) {
				const nextRoute = this.redirectingTo || DEFAULT_AFTER_LOGIN;
				this.#router.navigate([nextRoute], { replaceUrl: true });
			}
		} else {
			this.updateLoggedStatus(true);
		}
		this.#cdRef.detectChanges();
	}

	private openLogin(redirectTo?: string) {
		if (!this.#authProvider.isLogged) {
			Promise.resolve().then(() => {
				this.redirectingTo = redirectTo;
				this.#modalHandler
					.open<LoginModalComponent, void, boolean>(LoginModalComponent)
					.afterClosed().subscribe(
						() => { },
						() => {/* mostrar mensaje de error */});
			});
		}
	}

	private updateLoggedStatus(forceLogout?: boolean): boolean {
		this.showLogged = forceLogout ? false : this.#authProvider.isLogged;
		this.#cdRef.detectChanges();
		return this.showLogged;
	}

	ngOnInit() {
		this.#authProvider.onLoginRequested()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(this.openLogin.bind(this)); // listen to login requests
		this.#authProvider.onLoggedChanged()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(this.onLoggedChanged.bind(this));
		this.updateLoggedStatus();
		this.#flagsProvider.addFlagRequests()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(country => this.openAddFlag(country));
		this.#headerProvider.onHeaderSet()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(this.updateHeader.bind(this));
	}

	private updateHeader(newHeader) {
		this.header = newHeader;
		this.#cdRef.detectChanges();
	}

	private openAddFlag(country?: Country) {
		const config: MatDialogConfig<IAddFlagData> = AddFlagModalSize();
		if (country) {
			config.data = { country };
			// config = Object.assign<MatDialogConfig, MatDialogConfig<AddFlagData>>(config, { data: { country } });
		}
		// Issue was misunderstood at https://github.com/angular/material2/issues/11666
		this.#modalHandler.open<AddFlagModalComponent, IAddFlagData, void>(AddFlagModalComponent, config);
	}

	requestOpenLogin() {
		this.#authProvider.requestLogin();
	}

	goBack() {
		if (typeof this.header.backButton === 'string') {
			this.#router.navigate([`/${this.header.backButton}`]);
		} else {
			if (window.history.length > 2) { // 2 = browser's 'New Page' + current one
				this.#location.back();
			} else {
				this.#router.navigate(['/']);
			}
		}
	}

}
